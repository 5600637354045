import React, { createContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import CheckIcon from '@heroicons/react/outline/CheckIcon'
import CloseIcon from '@heroicons/react/outline/XIcon'
import AttentionIcon from '@heroicons/react/outline/ExclamationCircleIcon'

const DEFAULT_DURATION = 5000

type ToastMessage = {
  title: string
  message: string
  variant: 'success' | 'error'
  id: string
}

export type ToastArgs = {
  title: string
  message: string
  variant: 'success' | 'error'
  duration?: number | null
}

type ToastContextType = {
  addToast: (args: ToastArgs) => void
}

type ToastCallbacks = {
  onClose: (id: string) => void
}

export const ToastContext = createContext<ToastContextType>({
  addToast: () => undefined
})

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([])
  const value = {
    addToast: (args: ToastArgs) => {
      const id = uuidv4()
      setToasts((state) => [
        ...state,
        {
          id,
          ...args
        }
      ])
      let { duration } = args
      if (args.variant === 'success' && typeof duration === 'undefined') {
        duration = DEFAULT_DURATION
      }
      if (duration) {
        setTimeout(() => {
          setToasts((state) => state.filter((t) => t.id !== id))
        }, duration)
      }
    }
  }
  const onClose = (id: string) => {
    setToasts(toasts.filter((t) => t.id !== id))
  }

  return (
    <ToastContext.Provider value={value}>
      {children}
      {toasts.length > 0 &&
        toasts.map((toast, i) => (
          <ToastNotification
            {...toast}
            key={toast.id}
            index={i}
            onClose={onClose}
          />
        ))}
    </ToastContext.Provider>
  )
}

type ToastNotificationProps = ToastMessage & ToastCallbacks

function ToastNotification({
  id,
  title,
  message,
  variant,
  onClose,
  index
}: ToastNotificationProps & { index: number }) {
  return (
    <div
      className="fixed md:right-20 md:left-auto right-4 sm:left-4 rounded-lg shadow-md p-2 bg-white border-solid z-50"
      style={{ bottom: 20 + index * 84 }}
    >
      <div className="flex justify-between items-start">
        {variant === 'error' && (
          <AttentionIcon className="text-red-400 w-7 h-7" />
        )}
        {variant === 'success' && (
          <CheckIcon className="text-green-500 w-7 h-7 mr-1" />
        )}
        <div className="text-left">
          <h3 className="text-base leading-6 font-medium text-gray-900">
            {title}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{message}</p>
          </div>
        </div>
        <button onClick={() => onClose(id)} type="button">
          <CloseIcon className="text-gray-300 w-5 h-5 hover:text-gray-400 mt-1" />
        </button>
      </div>
    </div>
  )
}
