import RollbarConstructor from 'rollbar/src/browser/core'
import telemeter from 'rollbar/src/telemetry'
// import instrumenter from 'rollbar/src/browser/telemetry'
import wrapGlobals from 'rollbar/src/browser/wrapGlobals'
import scrub from 'rollbar/src/scrub'
import truncation from 'rollbar/src/truncation'

// https://github.com/rollbar/rollbar.js/pull/817
RollbarConstructor.setComponents({
  telemeter,
  // instrumenter,
  // polyfillJSON,
  wrapGlobals,
  scrub,
  truncation
})

const environment = process.env.NEXT_PUBLIC_VERCEL_ENV || 'development'

const isServer = typeof window === 'undefined'

const rollbar = new RollbarConstructor({
  enabled: environment === 'production',
  accessToken: '33d4315680c74a8695374fb1011e1f6b',
  hostSafeList: [
    'dashing-kit.io',
    'www.dashing-kit.io',
    'www.dashing-kit.com',
    'dashing-kit.com'
  ],
  captureUncaught: true,
  captureUnhandledRejections: isServer,
  autoInstrument: true,
  payload: {
    environment,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      }
    }
  }
})

export const setReportingUser = (
  user: { id?: number | string; email?: string } | null | undefined
) => {
  if (user) {
    rollbar.configure({
      payload: {
        person: {
          id: user.id,
          email: user.email
        }
      }
    })
  } else {
    rollbar.configure({
      payload: {
        person: null
      }
    })
  }
}

export default rollbar
