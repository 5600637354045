import 'utils/rollbar'

import type { AppProps } from 'next/app'
import Head from 'next/head'
import 'styles/reset.css'
import 'styles/tailwind.css'
import 'styles/global.css'
import 'components/prism/prism.css'
import React from 'react'
import 'what-input'
import { BlockUiProvider } from 'components/BlockUi'
import { ToastProvider } from 'components/Toast'
import { ConfirmDialogProvider } from 'components/ConfirmDialog'
import Modal from 'react-modal'
import '@dashing-kit/react/DashingActivity/styles.css'

if (typeof window !== 'undefined') {
  Modal.setAppElement('#__next')
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <link
          href="/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <meta content="initial-scale=1.0, width=device-width" name="viewport" />
        <meta content="#FF9800" name="theme-color" />
        <link
          href="/apple-icon-precomposed.png"
          rel="apple-touch-icon-precomposed"
          sizes="144×144"
        />
        <link
          crossOrigin="use-credentials"
          href={process.env.NEXT_PUBLIC_API_URL}
          rel="preconnect"
        />
        <title key="title">Dashing kit</title>
        <meta
          key="description"
          content="Dashing kit is very dashing"
          name="description"
        />
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `window.MSInputMethodContext && document.documentMode && document.write('<script src="https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js"><\\/script>');`
          }}
        />
      </Head>
      <BlockUiProvider>
        <ToastProvider>
          <ConfirmDialogProvider>
            <Component {...pageProps} />
          </ConfirmDialogProvider>
        </ToastProvider>
      </BlockUiProvider>
    </>
  )
}
