import React from 'react'
import cn from 'classnames'

export type ButtonStyle = {
  fullWidth?: boolean
  color: 'primary' | 'secondary' | 'danger'
  variant: 'outline' | 'contained' | 'transparent'
  size?: 'small' | 'normal' | 'large'
  shadow?: boolean
  albertsons?: boolean
  className?: string
}

export type ButtonProps = {
  children: React.ReactElement | string | (React.ReactElement | string)[]
  disabled?: boolean
  isAlbertsons?: boolean
} & ButtonStyle

export const buttonClass = ({
  fullWidth,
  size = 'normal',
  variant,
  color,
  shadow,
  className
}: ButtonStyle): string =>
  cn(
    'inline-flex items-center justify-center font-medium outline-none-mouse transition-all duration-200 rounded-md',
    className,
    {
      'w-full': fullWidth,
      'w-auto': !fullWidth,
      'shadow-sm': shadow && size === 'small',
      'shadow-md': shadow && (size === 'normal' || size === 'large'),
      'text-sm px-4 py-1 h-8': size === 'small',
      'text-base px-4 py-2 h-10': size === 'normal',
      'text-lg px-12 py-3 h-11': size === 'large',

      'bg-pink-600 hover:bg-pink-700 text-white disabled:bg-pink-200':
        color === 'primary' && variant === 'contained',
      'border border-solid border-pink-400 bg-white text-pink-700 hover:text-pink-800 hover:bg-pink-50 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-pink-500':
        color === 'primary' && variant === 'outline',

      'bg-gray-200 hover:bg-gray-300 text-gray-500':
        color === 'secondary' && variant === 'contained',
      'border border-solid border-gray-300 bg-white text-gray-600 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50':
        color === 'secondary' && variant === 'outline',

      'bg-red-600 hover:bg-red-700 text-white':
        color === 'danger' && variant === 'contained',
      'border border-solid border-red-600 bg-white text-gray-600 hover:bg-red-50 outline-none-mouse focus:ring-2 focus:ring-offset-2 focus:ring-red-500':
        color === 'danger' && variant === 'outline',

      'bg-transparent text-gray-200 border-gray-400 border-2 border-solid hover:text-white hover:border-gray-300 transition-all duration-200':
        color === 'secondary' && variant === 'transparent'
    }
  )

const Button = ({
  children,
  fullWidth,
  size = 'normal',
  variant,
  shadow,
  color,
  type = 'button',
  className,
  ...props
}: ButtonProps &
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={buttonClass({
        color,
        fullWidth,
        size,
        variant,
        shadow,
        className
      })}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
