import React from 'react'
import cn from 'classnames'

type Props = {
  className?: string
  text?: string
  size?: 'small' | 'base'
}

const Loading = ({ className, text = 'Loading...', size = 'base' }: Props) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center',
        {
          'my-10': size === 'base'
        },
        className
      )}
    >
      <svg
        className={cn('animate-spin mr-5 text-pink-500', {
          'h-8 w-8': size === 'base',
          'h-6 w-6': size === 'small'
        })}
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        />
      </svg>
      <p className="text-lg leading-6 font-medium text-gray-500">{text}</p>
    </div>
  )
}

export default Loading
